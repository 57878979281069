'use srtict';

document.addEventListener('DOMContentLoaded', function(){
	const openMenuBtn = document.querySelector('.js-burger'),
				mainMenu = document.querySelector('.h'),
				contactForm = document.querySelector('.contact__form'),
				contactMsg = document.querySelector('.contact__form-msg');

	openMenuBtn.addEventListener('click', function(){
		openMenuBtn.classList.toggle('active');
		mainMenu.classList.toggle('opened');
	});

	// go to anchor
	const anchors = document.querySelectorAll('a[href*="#"]');

	for (let anchor of anchors) {
		anchor.addEventListener('click', function (eventScroll) {
			eventScroll.preventDefault();
			const blockId = anchor.getAttribute("href").substr(1);

			document.getElementById(blockId).scrollIntoView({
				behavior: "smooth",
				block: "start",
			});

			if(openMenuBtn) {
				openMenuBtn.classList.toggle('active');
			}

			if(mainMenu.classList.contains('opened')) {
				mainMenu.classList.toggle('opened');
			}

		});
	};

	// team slider
	var swiperTeam = new Swiper(".team__slider", {
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
				spaceBetween: 8,
				loop: false
			},
			// when window width is >= 1280px
			1280: {
				slidesPerView: 3,
				spaceBetween: 40,
				loop: false
			}
		}
	});

	// portfolio slider
	var swiperPortfolio = new Swiper(".portfolio__slider", {
		navigation: {
			nextEl: ".portfolio__slider-next",
			prevEl: ".portfolio__slider-prev",
		},
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
				spaceBetween: 4,
				loop: false
			},
			// when window width is >= 1280px
			1280: {
				slidesPerView: 2,
				spaceBetween: 100,
				loop: false
			}
		}
	});

	// testimonial slider
	var swiperTestimonial = new Swiper(".testimonial__slider", {
		navigation: {
			nextEl: ".testimonial__slider-next",
			prevEl: ".testimonial__slider-prev",
		},
		pagination: {
			el: ".testimonial__slide-pagination",
			clickable: true
		},
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
				spaceBetween: 8,
				loop: false
			},
			// when window width is >= 1280px
			1280: {
				slidesPerView: 3,
				spaceBetween: 40,
				loop: false
			}
		}
	});

	// send form
	contactForm.addEventListener('submit', function(e) {
		e.preventDefault();

		fetch(contactForm.action, {
			method: contactForm.method,
			credentials: 'include',
		})
		.then(resp => resp.json())
		.then(
			(resp) => {
				contactMsg.textContent = resp.success;
				contactMsg.classList.add('contact__form-msg--success');
			},
			(error) => {
				contactMsg.textContent = resp.error;
				contactMsg.classList.add('contact__form-msg--error');
			}
		)
	})
	
});